import React from 'react'
import GiGBackgroud from '../../assets/icons/GiG.png'
import { Link, Outlet } from 'react-router-dom'
import StatueOfLiberty from '../../assets/images/StatueOfLiberty.jpg'
import Header from '../Inc/header'
import Footer from '../Inc/footer'

function nav() {
    return (
        <>
            <main className='content bg-danger'>
                <Header />
                <Outlet />
                <Footer />
            </main>
        </>
    )
}

export default nav
