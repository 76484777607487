import './App.css';
import Nav from './component/Frame/nav';
import { StrictMode } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import GiGEnergy from './component/child/about/GiGEnergy';
import VisionMission from './component/child/about/VisionMission';
import CoreValues from './component/child/about/CoreValues';
import GiGEnergyIncUSA from './component/child/about/GiGEnergyIncUSA';

import Dashboard from './component/child/dashboard/dashboard';

function App() {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='/' element={<Nav />}>
          <Route path='/' element={<Dashboard />} />
          <Route path='/about' element={<GiGEnergy />} />
          <Route path='/about'>
            <Route path='gig-energy-inc-usa' element={<GiGEnergyIncUSA />} />
            <Route path='vision-mission' element={<VisionMission />} />
            <Route path='core-values' element={<CoreValues />} />
            <Route path='gig-asia-inc' element={<CoreValues />} />
          </Route>
        </Route>
      </>
    )
  )  

  return (
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>
  );
}

/* 
  const NoPage = () => {
    return (
      <>
        <div className='content d-flex justify-content-center text-white'>
          <h3>404 Page Not Found😑</h3>
        </div>
      </>
    )
  }
*/

export default App;
