import React from 'react'
import Avail1 from '../../../assets/images/Avail1.jpg'

function CoreValues() {
    return (
        <div className='outlet d-flex justify-content-center align-items-center'>
            <div className='container p-3 text-white' style={{ width: '30%' }}>
                <h5>Highlight Core Values: </h5>
                <p className='text-justify'>Emphasizing corporate social responsibility, sustainability, ocean energy, zero carbon emission, and combating climate change</p>
            </div>
        </div>
    )
}

export default CoreValues
