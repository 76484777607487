import React from 'react'
import GiGBackgroud from '../../assets/icons/GiG.png'
import { Link, Outlet } from 'react-router-dom'
import { Navbar, Nav, Container, NavDropdown  } from 'react-bootstrap'

function Header() {

    
  return (
    <header className='header'>
        <Navbar expand="xl" bg="dark" data-bs-theme="dark">
            <Container fluid>
                <Link ><img src={GiGBackgroud} width={70} height={55} alt='GiG Logo'/>  <label style={{color: '#FFC000'}}><b>GIG ENERGY INC.</b></label></Link>
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">

                        {/* ABOUT US 😭 */}
                        <NavDropdown title="ABOUT US" id="basic-nav-dropdown">
                            <NavDropdown.Item>
                                <Link className='dropdown-item item-size-dp' to={'about'}>ABOUT GIG ENERGY INC.</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link className="dropdown-item item-size-dp" to={'about/gig-energy-inc-usa'}>GIG ENERGY INC, USA</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link className="dropdown-item item-size-dp" to={'about/gig-asia-inc'}>GIG-ASIA INC.</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link className="dropdown-item item-size-dp" to={'about/vision-mission'} href="#">MISSION & VISION</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link className="dropdown-item item-size-dp" to={`about/core-values`} >CORE VALUES</Link>
                            </NavDropdown.Item>
                        </NavDropdown>

                        {/* CORE BUSINESSES */}
                        <NavDropdown  title="CORE BUSINESSES" id="basic-nav-dropdown">
                            <NavDropdown.Item>
                                <Link className='dropdown-item item-size-dp' to={`core-business/our-business`} id="ourbusiness">CORE BUSINESSES</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link className="dropdown-item item-size-dp" to={`core-business/energy-solution`} href="#">ENERGY SOLUTION</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link className="dropdown-item item-size-dp" to={`core-business/technology-innovation`} href="#">TECHNOLOGY INNOVATION</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link className="dropdown-item item-size-dp" to={`core-business/asset-management`} href="#">ASSET MANAGEMENT</Link>
                            </NavDropdown.Item>
                        </NavDropdown>

                        {/* BUSINESS HIGHLIGHTS & GREEN BOND INITIATIVES */}
                        <NavDropdown  title="BUSINESS HIGHLIGHTS & GREEN BOND INITIATIVES" id="basic-nav-dropdown">
                            <NavDropdown.Item>
                                <Link className='dropdown-item item-size-dp' id="sustability"> BUSINESS HIGHLIGHTS & GREEN BOND INITIATIVES </Link>
                            </NavDropdown.Item>
                            {
                                /*
                                    <NavDropdown.Item>
                                        <Link className="dropdown-item item-size-dp" href="#">ENERGY SOLUTION</Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <Link className="dropdown-item item-size-dp" href="#">TECHNOLOGY INNOVATION</Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <Link className="dropdown-item item-size-dp" href="#">ASSET MANAGEMENT</Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <Link className="dropdown-item item-size-dp" to={`about/core-values`} >CORE VALUES</Link>
                                    </NavDropdown.Item>
                                */
                            }
                        </NavDropdown>

                        {/* INVESTOR */}
                        <NavDropdown  title="INVESTOR" id="basic-nav-dropdown">
                            <NavDropdown.Item>
                                <Link className='dropdown-item item-size-dp' to={`investor/our-investor`} id="sustability">INVESTOR</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link className="dropdown-item item-size-dp" to={`investor/explore-investment-oppotunities`} href="#">EXPLORE INVESTMENT OPPORTUNITIES</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link className="dropdown-item item-size-dp" to={`investor/upcomming-events`} href="#">UPCOMING EVENTS</Link>
                            </NavDropdown.Item>
                        </NavDropdown>

                        {/* STRATEGIC PARTNERS */}
                        <NavDropdown  title="STRATEGIC PARTNERS" id="basic-nav-dropdown">
                            <NavDropdown.Item>
                                <Link className='dropdown-item item-size-dp' to={`strategic-partners/our-strategic-partners`} id="partners">STRATEGIC PARTNERS</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link className="dropdown-item item-size-dp" to={`strategic-partners/pnoc-rc`} href="#">PNOC-RC</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link className="dropdown-item item-size-dp" to={`strategic-partners/up-mis`} href="#">UP-MIS</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link className="dropdown-item item-size-dp" to={`strategic-partners/yupangco-group-of-companies`} href="#">YUPANGCO GROUP OF COMPANIES</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link className="dropdown-item item-size-dp" to={`strategic-partners/imms`} href="#">ILOILO MERCHANT MARINE SCHOOL</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link className="dropdown-item item-size-dp" to={`strategic-partners/lawrence-livemore-national-laboratory`} href="#">LAWRENCE LIVEMORE NATIONAL LABORATORY</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link className="dropdown-item item-size-dp" to={`strategic-partners/noaa-ocean-team-explorers`} href="#">NOAA OCEAN TEAM EXPLORERS</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link className="dropdown-item item-size-dp" to={`strategic-partners/jamstec`} href="#">JAMSTEC</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link className="dropdown-item item-size-dp" to={`strategic-partners/bridges-gliders-&-sea-explorers`} href="#">BRIDGES - GLIDERS & SEA EXPLORERS</Link>
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Container>
        </Navbar>
    </header>
  )
}

export default Header