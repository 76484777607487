import React from 'react'

function Footer() {
  return (
        <footer className='footer bg-dark'>
            <div className='row text-white d-flex justify-content-lg-around align-items-center'>
                <div className='col-md-4 d-flex'>
                    <p>GIG Energy Inc., USA<br />753 Debra Street, Livermore CA, 94550<br /> Tel No.: 1 650-387-4164<br />Website: gignewenergy.com <br />Email: info@gignewenergy.com</p>
                </div>
                <div className='col-md-4'>
                    <p>GIG ASIA Inc.<br />G.A Yupangco Bldg. 339 Senator Gil Puyat Ave.<br />Makati, Metro Manila<br />Philippines 1209</p>
                </div>
            </div>
            <div className='container-fluid text-center text-white p-3'>
                <label>{`@ Copyright GIG ENERGY INC. ${new Date().getFullYear()} All Right Reserved `}</label>
            </div>
        </footer>
  )
}

export default Footer