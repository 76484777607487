import React, { useEffect, useState } from 'react'
import GiG_Logo from '../../../assets/images/Gig-Logo.png'
import Slide0 from '../../../assets/images/Main.png'
import Slide1 from '../../../assets/images/Climate.png'
import Moa1 from '../../../assets/images/MOA1.png'
import Moa2 from '../../../assets/images/MOA2.png'
import Ship from '../../../assets/images/Ship.png'
import SubSea from '../../../assets/images/SubSea.png'
import StatueOfLiberty from '../../../assets/images/StatueOfLiberty.jpg'


function Dashboard() {

  const [currSlideNumber, setCurrSlideNumber] = useState(0);
  const [currSlideMoaNumber, setCurrSlideMoaNumber] = useState(0);
  const [animationKey, setAnimationKey] = useState(0);
 
  useEffect(() => {
    const intervalId = setInterval(() => {
      
      if (currSlideNumber === null) {
        setCurrSlideNumber(0);
      } else {
        setCurrSlideNumber((prevSlideNumber) => (prevSlideNumber === 4 ? 0 : prevSlideNumber + 1));
      }
    }, 15000);

    return () => clearInterval(intervalId); 
  }, [currSlideNumber]); 

  useEffect(() => {
    const intervalId = setInterval(() => {
      
      if (currSlideMoaNumber === null) {
        setCurrSlideMoaNumber(0);
      } else {
        setCurrSlideMoaNumber((prevSlideNumber) => (prevSlideNumber === 1 ? 0 : prevSlideNumber + 1));
      }
    }, 7500);

    return () => clearInterval(intervalId); 
  }, [currSlideMoaNumber]);

  useEffect(() => {
    setAnimationKey((prevKey) => prevKey + 1);
  }, [currSlideNumber]);

  
  return (
    <>
      <div className='container-fluid content d-flex justify-content-evenly align-items-center flex-nowrap'  style={{  backgroundImage: `url(${StatueOfLiberty})`,  backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '100vh'  }}>
        <div key={animationKey} className='card shadow p-3' style={{ width: '900px', backgroundColor: '#1394E9' }}>
          <img src={GiG_Logo}  width={'170px'} alt='GIG_LOGO.png' />
          {
            currSlideNumber === 0 ?
            <>
              <div className='card-body card bg-dark background-image'  style={{ backgroundImage: `url(${Slide0})`, height: '500px', width: '100%'}}>
                <div className='row d-flex justify-content-start align-items-center h-100'>
                  <div className='col-md-5'>
                    <p className='text-justify LeftToRightAnimation title'>In Pursuit of Clean, Safe, Sustainable and Zero Carbon Energy forever.</p>
                  </div>
                </div>
              </div>
            </>
            :
            currSlideNumber === 1 ?
            <>
              <div className='card-body card bg-dark background-image' style={{ backgroundImage: `url(${Slide1})`, height: '500px', width: '100%'}}>
                <div className='container'>
                  <span className='title LeftToRightAnimation'>URGENT ACTION</span><br />  
                </div>
                <div className='row d-flex justify-content-start align-items-end h-100'>
                  <div className='col-md-10 '>
                    <p className='text-justify sub-title'>
                      <b>
                      INVESTIGATION & INVESTMENT NEEDED
                      TO MITIGATE GLOBAL CLIMATE CHANGE & ENERGY CRISIS, BEFORE “THRESHOLD for GLOBAL CATASTROPHE EXPIRES”. THE SOLUTION IS TO TRANSITION IN A SHORT PERIOD OF TIME FROM BURNING FOSSIL FUELS TO “NATURALLY SOURCE” DEUTERIUM /HEAVY HYDROGEN WHICH IS NATURALLY OCCURING &” KEPT CAPTIVE “ at the PHILIPPINE TRENCH THROUGH A CAREFUL AND RESPONSIBLE EXTRACTION.
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </>
            :
            currSlideNumber === 2 ?
            <>
              <div className='card-body card bg-dark background-image' style={{ backgroundImage: `url(${currSlideMoaNumber === 0 ? Moa1: Moa2})`, height: '500px', width: '100%'}}>
                <div className='container'>
                  <span className='title LeftToRightAnimation'>MEMORANDOM OF AGREEMENT (MOA)</span>
                </div>
                <div className='row d-flex justify-content-start align-items-end h-100'>
                  <div className='col-md-10 '>
                    <p className='text-justify sub-title'><br /> 
                      <b>
                        GIG Energy was able to secure a Memorandum of Agreement (MOA) that has a long-term agreement of 25-years and renewable. The goal initially, is to perform preliminary assessment in the Philippine Trench through its company research and development  (R&D) as refer to pre-developmental stage.
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </>
            :
            currSlideNumber === 3 ?
            <>
              <div className='card-body card bg-dark background-image' style={{ backgroundImage: `url(${Ship})`, height: '500px', width: '100%'}}>
                <div className='container'>
                  <span className='title LeftToRightAnimation'>GIG ENERGY INC. – <br /> ‘ PROOF OF CONCEPT ’</span>
                </div>
                <div className='row d-flex justify-content-start align-items-end h-100'>
                  <div className='col-md-10 '>
                    <p className='text-justify sub-title'><br /> 
                      <b>
                      The proof of concept presented here involves a multi-pronged approach by leveraging the expertise of companies / government agencies such as NOAA Ocean Team Explorer, JAMSTEC, and the innovative partner organization with Lawrence Livermore National Laboratory in collaboration with GIG Energy. The goal is to extract, develop, and commercially produce deuterium and hydrogen from a Philippine-owned corporation, all without relying on traditional electrolysis processes.
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </>
            :
            currSlideNumber === 4 ?
            <>
              <div className='card-body card bg-dark background-image' style={{ backgroundImage: `url(${SubSea})`, height: '500px', width: '100%'}}>
                <div className='container'>
                  <span className='title LeftToRightAnimation'>MARKET POTENTIAL</span>
                </div>
                <div className='row d-flex justify-content-start align-items-end h-100'>
                  <div className='col-md-10'>
                    <p className='text-justify sub-title'><br /> 
                      <b>
                      According to Bloomberg, the nuclear fusion industry is nearing a market valuation of $40 trillion, with the US Department of Energy planning to integrate a fusion pilot plant into the electrical grid by 2035.  
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </>
            :
            <>
            </>
          }
          <div className='container-fluid text-center mt-3'>
            <input type='color' value={currSlideNumber === 0 ? '#0000FF' : '#9B9594'} className='no-border' disabled />
            <input type='color' value={currSlideNumber === 1 ? '#0000FF' : '#9B9594'} className='no-border' disabled />
            <input type='color' value={currSlideNumber === 2 ? '#0000FF' : '#9B9594'} className='no-border' disabled />
            <input type='color' value={currSlideNumber === 3 ? '#0000FF' : '#9B9594'} className='no-border' disabled />
            <input type='color' value={currSlideNumber === 4 ? '#0000FF' : '#9B9594'} className='no-border' disabled />
          </div>
        </div>
      </div>
    </>
    
  )
}

export default Dashboard