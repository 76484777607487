import React, { useEffect, useState } from 'react'
import vision from '../../../assets/images/vision.jpg'
import mission from '../../../assets/images/mission.jpg'

function VisionMission() {

    // const [ currentPage, setCurrentPage ] = useState(0)

    /* 
        const handleNext = () => {
            setTimeout(() => {
                setCurrentPage(currentPage+1)
            }, 2000)
        }

        const handleBack = () => {
            setTimeout(() => {
                setCurrentPage(currentPage-1)
            }, 2000)
        }
    */

    // Switch Display Every 10 sec
    useEffect(()=> {
        setTimeout(() => {
            // setCurrentPage(currentPage === 0 ? 1 : 0)
        }, 10000)
    })

    return (
        
        <div className='d-flex justify-content-center align-items-center flex-nowrap  text-white p-5'>
            <div className='row' >
                <div className='col-lg-6 LeftToRightAnimation'>
                    <div className='row'>
                        <div className='col-12 mb-3'>
                            <h4 className='text-center'>VISION</h4>
                        </div>
                        <div className='col-12'>
                            <div className='border-0'>
                                <p className='text-justify'>
                                    The company envisions a world where carbon energy is replaced by a clean, safe, and sustainable solution to address the pressing issues of climate change and the energy crisis while fulfilling its energy needs without compromising the well-being of future generations.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 LeftToRightAnimation'>
                    <div className='row'>
                        <div className='col-12 mb-3'>
                            <h4 className='text-center'>MISSION</h4>
                        </div>
                        <div className='col-12'>
                            <div className='card-body flex-sm-nowrap'>
                                <p className='text-justify'>
                                    The company must uphold the values of love, devotion to one's nation, and addressing the pressing issue of climate change through the development of innovative solutions.<br /> The mission of the Deuterium Project is threefold. 
                                </p>
                                <ul>
                                    <li className='text-justify'>First, it aims to conduct extensive research and development to advance the understanding and utilization of deuterium as an alternative energy source. This involves exploring its potential applications in various sectors, such as transportation, electricity generation, and industrial processes. By developing innovative technologies and methodologies to harness deuterium, the project strives to provide practical and efficient solutions to climate change.</li>
                                    <li className='text-justify'>Second, the Deuterium Project seeks to collaborate with governments, organizations, and individuals worldwide to promote the adoption of deuterium-based technologies. By fostering partnerships and sharing knowledge, the project aims to create a global network of researchers, scientists, engineers, and policymakers dedicated to combating climate change through sustainable energy solutions.</li>
                                    <li className='text-justify'>Finally, the project aims to raise awareness about the importance of addressing climate change and the potential of deuterium as a renewable energy source. Public outreach activities, educational programs, and advocacy campaigns will be undertaken to inform and engage people from all walks of life in the global effort to curb greenhouse gas emissions and protect our environment.</li>
                                </ul>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VisionMission