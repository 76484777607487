import React from 'react'
import Climate from '../../../assets/images/Climate.png'
import BackgroundImage01 from '../../../assets/images/1.png'

function GiGEnergy() {
    // style={{ backgroundImage: `url(${BackgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}

  return (
    <div className='container-fluid d-flex justify-content-center text-white align-items-center flex-nowrap flex-xl-column flex-sm-row m-3'>
      <div className='row  -flex justify-content-center align-items-center'>
        <div className='col-md-6'>
          <div className='container-fluid mt-4'>
            <div className='text-justify LeftToRightAnimation '>
              <p><b>GIG Energy Inc.</b> USA & GIG Asia Inc. Philippines, are a startup corporation registered in California (Entity No. 5660428) with a mission to bridge the technological gap between the Philippine Department of Energy / the Philippine National Oil Company Renewable Corporation, a government-owned entity, and the United States Energy needs & the world. It aims to leverage its expertise and resources to conduct research and exploration in the Philippines Trench to <b>prove and certify the presence of Deuterium</b>. </p>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='container-fluid mt-4'>
            <div className='text-justify text-center'>
              <img src={BackgroundImage01} height={'450px'} alt='Climate Change image' />
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='container-fluid mt-4'>
            <div className='text-justify text-center'>
              <img src={Climate} width={'50%'} alt='Climate Image' />
            </div>
          </div>
        </div>
        <div className='col-md-6  d-flex justify-content-center align-items-center'>
          <div className='container-fluid mt-4'>
            <div className='text-justify LeftToRightAnimation'>
              <p><b>URGENT ACTION IS REQUIRED: </b><br/>INVESTIGATION & INVESTMENT NEEDED TO MITIGATE GLOBAL CLIMATE CHANGE & ENERGY CRISIS, BEFORE “THRESHOLD for GLOBAL CATASTROPHE EXPIRES”.<br />THE SOLUTION IS TO TRANSITION IN A SHORT PERIOD OF TIME FROM BURNING FOSSIL FUELS TO “NATURALLY SOURCE” DEUTERIUM /HEAVY HYDROGEN WHICH IS NATURALLY OCCURING &” KEPT CAPTIVE “ <br />at the PHILIPPINE TRENCH THROUGH A CAREFUL AND RESPONSIBLE EXTRACTION. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GiGEnergy
