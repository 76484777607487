import React from 'react'
import Partner from '../../../assets/images/Partners.png'
import Solution from '../../../assets/images/Solution.png'

function GiGEnergyIncUSA() {
  return (
    <div className='d-flex justify-content-center flex-nowrap text-white'>
      <div className='row d-flex justify-content-center align-items-center p-4'>
        <div className='col-md-6 '>
          <div className='container text-justify p-3'>
            <p>
              <b>DETAILS</b>
              <br />
              GIG Energy Inc. USA & GIG Asia Inc. Philippines, are a startup corporation registered in California (Entity No. 5660428) with a mission to bridge the technological gap between the Philippine Department of Energy / the Philippine National Oil Company Renewable Corporation, a government-owned entity, and the United States Energy needs & the world.
              It aims to leverage its expertise and resources to conduct research and exploration in the Philippines Trench to prove and certify the presence of Deuterium.
            </p>
          </div>
        </div>
        <div className='col-md-6 '>
          <div className='container text-center'>
            <img width={'400px'} src={Partner} alt='Partner Agency' style={{ borderRadius: '10px' }} />
          </div>
        </div>
        <div className='col-md-6 '>
            
        </div>
        <div className='col-md-6  text-justify'>
          <div className=''>
            <b>URGENT ACTION IS REQUIRED</b>
              <br />
              INVESTIGATION & INVESTMENT NEEDED TO MITIGATE GLOBAL CLIMATE CHANGE & ENERGY CRISIS, BEFORE “THRESHOLD for GLOBAL CATASTROPHE EXPIRES”. 
            <br /><br />
          </div>
        </div>

        <div className='col-md-6 '>
          <p>
            <b>SOLUTION</b><br />
            TRANSITION IN A SHORT PERIOD OF TIME FROM BURNING FOSSIL FUELS TO “NATURALLY SOURCE” DEUTERIUM /HEAVY HYDROGEN WHICH IS NATURALLY OCCURING &” KEPT CAPTIVE “
            at the PHILIPPINE TRENCH THROUGH A CAREFUL AND RESPONSIBLE EXTRACTION.
          </p>
        </div>
        <div className='col-md-6 '>
          <div className='container text-center'>
            <img width={'400px'} src={Solution} alt='Partner Agency' style={{ borderRadius: '10px' }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GiGEnergyIncUSA